/**
 * Convert a html file object to base64 string.
 *
 * @param file
 * @param trimMimeType
 * @return Promise base64 string
 */
export function toBase64(file: File, trimMimeType: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            if (trimMimeType) {
                return resolve(reader.result?.toString().split(',')[1] || '');
            } else {
                return resolve(reader.result?.toString() || '');
            }
        };

        reader.onerror = (error) => reject(error);
    });
}

/**
 * Generate a download link for a BLOB.
 *
 * @param blobUrl
 * @param filename
 */
export function generate_download(blobUrl: string, filename: string) {
    const aElement = document.createElement('a');

    aElement.setAttribute('download', filename);
    aElement.href = blobUrl;
    aElement.setAttribute('target', '_blank');
    aElement.click();

    URL.revokeObjectURL(blobUrl);
}
